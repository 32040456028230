import React from "react"
import { Link } from "gatsby"


import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import "../styles/style.sass"
import Formlayout from "../components/formlayout"

const Login = () => (
  <div>
    <SEO title="Login" />
    {/* form */}
    <Formlayout>
      <form className="form" method="post">
          {/* username */}
          <div className="input" >
              <label for="Username">Username</label>
              <input type="text" id="username" name="username" value="Username"></input>
          </div>
          {/* password */}
          <div className="input" >
              <label for="pwd">Password</label>
              <input type="password" id="pwd" name="pwd" value="Password"></input>
          </div>
        <Link to="/questionnaire/"><input type="submit" value="Submit"></input></Link>
      </form>
    </Formlayout>
  </div>
)

export default Login