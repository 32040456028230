import React,{Component} from "react"
import { useStaticQuery, graphql } from "gatsby"

import "../styles/formlayout.sass"
import "../components/layout.css"

const Formlayout = ({children}) => {
    return(
        <div className="formlayout" >
            {children}
        </div>
    )
}

export default Formlayout